import React, { useEffect, useState } from "react";
import CourseApi from "../api/services/CourseApi";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import scrollToTop from "../components/ScrollTop/ScrollTop";
import BackButton from "../components/microComponents/BackButton";
import Urls from "../api/Urls";
import ZoomModal from "../components/microComponents/ZoomModal";

const skeleton = (
  <>
    <div className="importentNotesMain">
      {[...Array(6)].map((_, index) => (
        <div className="notesItem" key={index}>
          <div className="notesImg">
            <Skeleton circle={true} height={60} width={60} />
            <p>
              <Skeleton width={100} />
            </p>
          </div>
          <div className="notesDetails">
            <Skeleton count={3} />
          </div>
        </div>
      ))}
    </div>
  </>
);

const Dictionary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [wordList, setWordList] = useState<any[]>([]);
  const [nextPageNo, setNextPageNo] = useState(0);

  useEffect(() => {
    scrollToTop();
    fetchApiData();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);

    CourseApi.getDictionaryList(1, {
      onSuccess: (response) => {
        setIsLoading(false);
        setWordList(response.data);
        setNextPageNo(response?.meta?.next_page_url ? 2 : 0);
      },
      onError: () => setIsLoading(false),
    });
  };

  /**
   * This funtion fetch more data
   */
  const handleLoadMore = () => {
    // console.log('called handleLoadMore');
    if (!isLoading && nextPageNo > 0) {
      setIsLoading(true);
      CourseApi.getDictionaryList(nextPageNo, {
        onSuccess: (response) => {
          setIsLoading(false);
          setWordList(
            response?.data ? [...wordList, ...response.data] : [...wordList]
          );
          setNextPageNo(response?.meta?.next_page_url ? nextPageNo + 1 : 0);
        },
        onError: () => setIsLoading(false),
      });
    }
  };

  return (
    <div className="Container">
      <BackButton title={"শব্দার্থ শিখুন"} isSticky={true} />

      {isLoading && wordList.length === 0 ? (
        skeleton
      ) : (
        <InfiniteScroll
          dataLength={wordList?.length}
          next={handleLoadMore}
          hasMore={nextPageNo > 0 ? true : false}
          loader={skeleton}
          // endMessage={<h4>No more items</h4>}
        >
          <div className="importentNotesMain">
            {wordList?.map((item) => (
              <div className="notesItem" key={item.id}>
                {" "}
                <div className="notesImg">
                  <ZoomModal img={Urls.storagePath + item.image}>
                    <img src={Urls.storagePath + item.image} alt="" />
                  </ZoomModal>
                  <p>{item?.word}</p>
                </div>
                <div
                  className="notesDetails"
                  dangerouslySetInnerHTML={{ __html: item.meaning }}
                />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Dictionary;
