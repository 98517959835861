import React, { useEffect, useState } from "react";
import book from "../assets/lezione.svg";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BackButton from "../components/microComponents/BackButton";
import CourseApi from "../api/services/CourseApi";
import Urls from "../api/Urls";
import dummy from "../assets/dummy.jpeg";
import useCourseStore from "../stores/useCourseStore";
import Skeleton from "react-loading-skeleton";
import scrollToTop from "../components/ScrollTop/ScrollTop";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import useUserStore from "../stores/useUserStore";

const skeleton = (
  <>
    <div className="ManualConQuiz">
      {Array.from({ length: 12 }).map((_, index) => (
        <div className="item_col" key={index}>
          <div className="ManualConQuiz_item">
            <Skeleton height={80} width={80} />

            <div className="info">
              <div className="image">
                <Skeleton circle={true} height={16} width={16} />
                <Skeleton height={16} width={20} />
              </div>
              <span className="item_name" style={{ width: "100%" }}>
                <Skeleton />
                <Skeleton width={150} />
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
);

const Chapters = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const navigate = useNavigate();
  const isLogedIn = useUserStore((state) => state.isLogedIn);
  const user = useUserStore((state) => state.user);
  const patenteLessons = useUserStore((state) => state.patenteLessons);
  const [isLoading, setIsLoading] = useState(false);
  const chapters = useCourseStore((state) => state.chapters);
  const setChapters = useCourseStore((state) => state.setChapters);
  const setSections = useCourseStore((state) => state.setSections);

  useEffect(() => {
    scrollToTop();
    getChapters();
  }, []);

  const getChapters = () => {
    setIsLoading(true);
    CourseApi.getChapters(Number(id), {
      // showIndicator: true,
      onSuccess: (response) => {
        setIsLoading(false);
        setChapters(response.data);
      },
      onError: () => setIsLoading(false),
    });
  };

  const onChapterClick = (item: any) => {
    if (!type && item.status === "public") {
      return navigate(`/ChapterDetails/${item.id}`);
    }

    if (!isLogedIn) {
      return navigate("/login");
    }

    if (patenteLessons?.user_has_course) {
      if (type === "video") {
        return navigate(`/videos/${item.id}`);
      } else if (type === "patente-boi") {
        return navigate(`/patente-boi/${item.id}`);
      }

      setSections([]);
      return navigate(`/ChapterDetails/${item.id}`, {
        state: {
          chapterName: item?.chapter_name_italian,
          introduction: item?.introduction,
        },
      });
    } else {
      // console.log(patenteLessons?.user_has_course);
      onRequestPressed();
    }
  };

  // const getRouteUrl = (chapterId: string) => {
  //   let route = `/ChapterDetails/${chapterId}`;
  //   if (type === "video") {
  //     route = `/videos/${chapterId}`;
  //   } else if (type === "patente-boi") {
  //     route = `/patente-boi/${chapterId}`;
  //   }

  //   return route;
  // };

  const onRequestPressed = () => {
    confirmAlert({
      title: "Request course?",
      message:
        "Unfortunately, you do not have permission to view the entire course. There is a €_PRICE_ fee for accessing the full course. Would you like to request this course in order to gain full access?".replace(
          "_PRICE_",
          patenteLessons.price
        ),
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            CourseApi.requestCourse(
              { user_id: user.id, course_id: patenteLessons.id },
              {
                onSuccess: (repponse) => {
                  console.log("requestCourse", repponse);
                },
              }
            );
          },
          className: "button-yes",
        },
        {
          label: "No",
          onClick: () => console.log("Action canceled!"),
          className: "button-no",
        },
      ],
    });
  };

  // console.log("isLogedIn", isLogedIn);
  return (
    <div className="Container pb-4">
      <div className="ManualConQuiz_main">
        <BackButton title={"Chapters"} isSticky={true} />
        {isLoading && chapters.length === 0 ? (
          skeleton
        ) : (
          <div className="ManualConQuiz">
            {chapters.map((item) => (
              <div className="item_col" key={item.id}>
                <div
                  // to={"/"}
                  // state={{
                  //   chapterName: item?.chapter_name_italian,
                  //   introduction: item?.introduction,
                  // }}
                  onClick={() => onChapterClick(item)}
                  className="ManualConQuiz_item"
                >
                  <img
                    className="img"
                    src={
                      item?.media?.file_path
                        ? Urls.storagePath + item?.media?.file_path
                        : dummy
                    }
                    alt="back arrow"
                  />
                  <div className="info">
                    <div className="image">
                      <img src={book} width={16} height={16} alt="book icon" />
                      <span>{item?.id}</span>
                    </div>
                    <span className="item_name">
                      {item?.chapter_name_italian}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chapters;
